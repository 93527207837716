import React, { Component } from 'react';

class Header extends Component {
  render() {
    return (
        <header>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 1V28H32V30H1V1H3ZM10 1H39V3H12V19H39V39H1V37H37V21H10V1ZM19 10H39V12H19V10Z" fill="white"/>
          </svg>
          <ul class="links">
            <li class="link-item"><a href="mailto:lukees@me.com " title="Email" class="link">Email</a></li>
            <li class="link-item"><a href="https://bsky.app/profile/madeyouluke.bsky.social" title="Bluesky profile link" class="link">Bluesky</a></li>
          </ul>
        </header>
    );
  }
}

export default Header;
